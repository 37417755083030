.authentication-theme.auth-style_spt{
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-height: 100vh;

  .inner-wrapper {
    width: 100%;
  }

  .banner-bg {
    background: transparent url("../images/test_bg.svg") no-repeat center;
    background-size: 100%;
    background-color: #fff;
  }
  .banner-section {
    @media (max-width: 580px) {
      background: none;
    }

    h1 {
      width: 100%;
      margin-top: 3rem;
      padding: 0 auto;
      text-align: center;
    }
    .small {
      width: 100%;
      margin-top: 0.5rem;
      padding: 0 auto;
      text-align: center;
    }
  }

  .logo-section {
    margin: 0 auto 25px auto;
    display: flex;
    justify-content: center;
    text-align: center;
    p {
      font-size: 18px;
      max-width: 60%;
    }
  }

  .form-section {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    form {
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
      padding-bottom: 40px;
    }
  }

  .devise-other-users-login {
    margin-top: 3rem;
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
}

/* Footer */

footer {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 2rem $body-margin-x;
  max-width: $body-container-width;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(md) {
    padding: 20px 0px 30px 0;
  }

  ul {
    margin-left: 0;
    padding-left: 0;

    li {
      display: inline-block;
      padding: 0 10px 0 0;
      line-height: 1;

      &:not(:last-child) {
        margin-right: 10px;
        border-right: $border-width $border-style $border-color;
      }

      a {
        @include ms-respond(font-size, -2);
        font-weight:600;
        letter-spacing: $letter-spacing;
        color: $text-gray;
      }
    }
  }
}
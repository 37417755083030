/* Timeline */

.timeline-vertical {
    width: calc(100% + 30px);
    padding-right: 30px;
    margin-top: 20px;

    .activity-log {
        padding-left: 25px;
        padding-bottom: 25px;
        margin-bottom: 0px;
        position: relative;
        padding-top: 5px;
        display: grid;
        grid-template-columns: auto 90px;
        grid-template-rows: auto auto;

        &:after {
            content: "";
            position: absolute;
            left: 3px;
            border-left: $border-width dashed $border-color;
            height: 100%;
            width: 1px;
            top: 10px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: -4px;
            background: theme-color(primary);
            border: 4px $border-style $card-bg;
            width: 15px;
            height: 15px;
            border-radius: 100px;
            z-index: 1;
        }

        .log-name {
            font-weight: 500;
            @include ms-respond(font-size, -1);
            line-height: 1.2;
            margin-bottom: 0;
        }

        .log-details {
            font-weight: 400;
            @include ms-respond(font-size, -1);
            line-height: 1.4;
            color: $text-gray;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 2;
            margin-top: 2px;
        }

        .log-time {
            display: block;
            color: $text-muted;
            font-weight: 400;
            @include ms-respond(font-size, -2);
            grid-column-start: 2;
            grid-row-start: 1;
            text-align: right;
            line-height: 1;
        }
    }
}

.vertical-timeline-wrapper {
    overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0;
}

h1.page-heading {
    @extend .mb-4;
}
.test-passage-container {
  min-height: 100vh;
}
.test-passage-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .2;
  z-index: -1;
  background-image: url("../images/test_bg.svg");
}

.test-passage-container > * {
  opacity: 1;
}

.passages-card {
  background: rgba(255, 255, 255, 0.85);
  border: rgba(105,105,105,0.15) 1px solid;
  margin-top: 50px;
  margin-bottom: 350px;

  .high-contrast-toggle {
    position: absolute;
    right: 10px;
    top: 10px;

    .off {
      display: none;
    }

    a {
      color: black;
      i {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 580px) {
  body.high-contrast .passages-card {
  }
}



body.high-contrast {
  .test-passage-container {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;

    &> .col-12, .col-md-9 .col-lg-8 {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  .text-gray {
    color: black;
  }

  p {
    font-size: 25px;
  }

  .passages-card {
    background: rgba(255, 255, 255, 0.85);
    border: rgba(105,105,105,0.15) 1px solid;
    margin-top: 0;
    margin-bottom: auto;
    width: 100%;

    h3, h4 {
      font-size: 40px;
    }

    .btn {
      border: black 2px solid;
      background-color: white;
      color: black;
      height: auto;
      max-height: none;

      font-size: 25px;
      line-height: 1.3;

      &:hover, &:focus {
        border: black 2px solid;
        color: white;
        background-color: black;
      }
    }

    .high-contrast-toggle {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
      background-color: transparent;

      .on {
        display: none;
      }
      .off {
        display: inline-block;
      }
    }
  }
}

.question-content {
  min-height: 100px;
  font-size: 20px;
}

.text-justify {
  p {
    text-indent: 30px;
    margin-bottom: 5px;
  }
}

#mask {
  position: absolute;
  right: 10px;
  top: 10px;
}


w-20 {
  width: 20%;
}

.exit {
  float: right;
  i {
    font-size: 16px;
  }
}


// Slider input
$shade-10: #2c3e50 !default;
$shade-1: #d7dcdf !default;
$shade-0: #fff !default;
$colour: #F0F !default;


// Range Slider
$range-width: 100% !default;

$range-handle-color: $shade-10 !default;
$range-handle-color-hover: $colour !default;
$range-handle-size: 32px !default;

$range-track-color: $shade-1 !default;
$range-track-height: 10px !default;

$range-label-color: $shade-10 !default;
$range-label-width: 60px !default;

.range-slider {
  width: $range-width;
  margin: 20px 0 60px 0;
}

.range-labels {
  width: 90%;
  margin-left: -70px;
  display: flex;
  justify-content: space-between;
}


.range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (#{$range-label-width + 13px}));
  height: $range-track-height;
  border-radius: 5px;
  background: $range-track-color;
  background: linear-gradient(to right, red 0%, yellow 60%, green 100%);
  outline: none;
  padding: 0;
  margin: 0;

  // Range Handle
  &::-webkit-slider-thumb {
    appearance: none;
    width: $range-handle-size;
    height: $range-handle-size;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background .15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-webkit-slider-thumb {
    background: $range-handle-color-hover;
  }

  &::-moz-range-thumb {
    width: $range-handle-size;
    height: $range-handle-size;
    border: 0;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background .15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-moz-range-thumb {
    background: $range-handle-color-hover;
  }
}


// Range Label
.range-slider__value {
  display: inline-block;
  position: relative;
  width: $range-label-width;
  color: $shade-0;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
  border-radius: 3px;
  background: $range-label-color;
  padding: 5px 10px;
  margin-left: 8px;

  &:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid $range-label-color;
    border-bottom: 7px solid transparent;
    content: '';
  }
}

// Firefox Overrides
::-moz-range-track {
  background: $range-track-color;
  background: linear-gradient(to right, green 0%, yellow 50%, red 100%);
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

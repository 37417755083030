/* Error Pages */

.error_page {
    &.error_2 {
        display: flex;
        align-items: stretch;
        min-height: 100vh;
        background: $card-bg;

        .inner-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .error-heading {
                @include ms-respond(font-size, 20);
                font-weight: 600;
                line-height: 1;
            }

            .error-code {
                margin-top: 10px;
                font-weight: 600;
            }

            .error-message {
                max-width: 500px;
                margin-top: 5px;
                text-align: center;
            }

            .btn {
                margin: 30px auto 0 auto;
            }
        }
    }
}